import React from 'react';

const AddButton = (props) => {
  const { addInputRow, id } = props;
  return (
    <button type="button" className="add-button" onClick={addInputRow} data-testid={`${id}-button`}>
      <span className="button-text">Přidat</span>
      <span className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M10.625 9.375V5H9.375V9.375H5V10.625H9.375V15H10.625V10.625H15V9.375H10.625Z" fill="#03031A" />
        </svg>
      </span>
    </button>
  );
};

export default AddButton;
