import { useState } from 'react';
import './Failure.scss';
import { Alert } from 'react-bootstrap';

const Failure = (props) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    props.onClick(false);
  };

  return (
    <>
      <Alert show={show} variant="danger" onClose={() => handleClose()} dismissible>
        <p>Váš požadavek se z důvodu technického problému nepodařilo odeslat!</p>
        <hr />
        <p>Vyzkoušejte formulář odeslat později.</p>
      </Alert>
    </>
  );
};

export default Failure;
