import client from '../api/Api';
import axios from 'axios';

function get(urlPart) {
  return axios.get(urlPart);
}

function create(urlPart, data) {
  return client.post(urlPart, data);
}

function updatePut(urlPart, data) {
  return client.put(urlPart, data);
}

function updatePatch(urlPart, data) {
  return client.patch(urlPart, data);
}

function remove(urlPart) {
  return client.delete(urlPart);
}

export { get, create, updatePut, updatePatch, remove };
