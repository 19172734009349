import axios from 'axios';
import { API_BASE_URL, CONSUMER_KEY, CONSUMER_SECRET, ENP_AUTH_V1_LOGIN_ANONYMOUS } from '../settings/config';

const GenerateBearerToken = async () => {
  try {
    const customConfig = {
      headers: {
        Authorization: `Basic ${btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`)}}`,
        'Content-Type': 'application/json'
      }
    };
    const response = await axios.post(
      `${API_BASE_URL}/${ENP_AUTH_V1_LOGIN_ANONYMOUS}`,
      { grant_type: 'client_credentials' },
      customConfig
    );
    const token = response.data.access_token;
    return token;
  } catch (error) {
    console.log('Chyba při generování tokenu:', error);
    throw error;
  }
};

const BearerToken = {
  GenerateBearerToken
};

export default BearerToken;
