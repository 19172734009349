import React from 'react';
import { useEffect } from 'react';

const RadioInput = (props) => {
  const setProgrammaticLocation = (location) => {
    // Simulate an event object
    const event = {
      target: {
        value: location
      }
    };

    props.onChange(event); // Call onChange with the simulated event object
  };

  useEffect(() => {
    // Automatically select the radio input based on the URL parameter
    if (props.checked === 'DU') {
      document.getElementById(props.testId[0]).checked = true;
      setProgrammaticLocation(props.checked);
    } else if (props.checked === 'TE') {
      document.getElementById(props.testId[1]).checked = true;
      setProgrammaticLocation(props.checked);
    }
  }, [props.checked]);

  return (
    <div className="form-component">
      <label htmlFor={props.id} className="form-label">
        {props.label}
      </label>
      <div className="location">
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="flexRadio"
            id={props.testId[0]}
            data-testid={props.testId[0]}
            value="DU"
            onChange={props.onChange}
            required
          />
          <label className="form-check-label radio-item" htmlFor={props.testId[0]}>
            Dukovany
          </label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="radio"
            name="flexRadio"
            id={props.testId[1]}
            data-testid={props.testId[1]}
            value="TE"
            onChange={props.onChange}
            required
          />
          <label className="form-check-label radio-item" htmlFor={props.testId[1]}>
            Temelín
          </label>
        </div>
      </div>
    </div>
  );
};

export default RadioInput;
