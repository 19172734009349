export const FileExtensionsEnum = [
  { name: 'PDF', extension: '.pdf', type: 'application/pdf' },
  { name: 'DOC', extension: '.doc', type: 'application/msword' },
  { name: 'DOCX', extension: '.docx', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { name: 'XLS', extension: '.xls', type: 'application/vnd.ms-excel' },
  { name: 'XLSX', extension: '.xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { name: 'PPT', extension: '.ppt', type: 'application/vnd.ms-powerpoint' },
  {
    name: 'PPTX',
    extension: '.pptx',
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  { name: 'JPEG', extension: '.jpg', type: 'image/jpeg' },
  { name: 'JPEG', extension: '.jpeg', type: 'image/jpeg' },
  { name: 'PNG', extension: '.png', type: 'image/png' },
  { name: 'GIF', extension: '.gif', type: 'image/gif' },
  { name: 'ZIP', extension: '.zip', type: 'application/zip' },
  { name: 'RAR', extension: '.rar', type: 'application/x-rar-compressed' }
];
