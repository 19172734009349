import { useState } from 'react';
import { FileExtensionsEnum } from '../helpers/enums/FileExtensionsEnum';

function useSharedState() {
  const [checkBox, setCheckBox] = useState(false);
  const [textAreaCounter, setTextAreaCounter] = useState(0);
  const [textInputCounter, setTextInputCounter] = useState(0);
  const [validated, setValidated] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [kpjm, setKpjm] = useState('');
  const [email, setEmail] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedTextInput, setSelectedTextInput] = useState('');
  const [selectedTextAreaInput, setSelectedTextAreaInput] = useState('');
  const [selectedResolutionDateTime, setSelectedResolutionDateTime] = useState({
    datePicker: new Date().toISOString().slice(0, 10)
  });
  const [selectedEventDateTimePicker, setSelectedEventDateTimePicker] = useState('');

  const handleOnChangeCheckBox = (e) => {
    setCheckBox(e.target.checked);
  };

  const handleOnChangeRadio = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleOnChangeKpjm = (e) => {
    setKpjm(e.target.value);
  };

  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleOnChangeTextInput = (e) => {
    setSelectedTextInput(e.target.value);
    handleTextInputCounter(e.target.value);
  };

  const handleOnChangeTextAreaInput = (e) => {
    setSelectedTextAreaInput(e.target.value);
    handleTextAreaCounter(e.target.value);
    var inputTextArea = document.getElementById('description');
    if (!inputTextArea.value.trim()) {
      inputTextArea.setCustomValidity('Prosím, zadejte platný text (alespoň jeden znak, který není white space).');
    } else {
      inputTextArea.setCustomValidity('');
    }
  };

  const handleOnChangeSource = (e) => {
    setSelectedSource(e.target.value);
  };

  const handleOnChangeResolutionDateTimePicker = (e) => {
    if (e.target.id === 'datePicker') {
      setSelectedResolutionDateTime({
        ...selectedResolutionDateTime,
        datePicker: e.target.value
      });
      if (e.target.value < selectedEventDateTimePicker.datePicker) {
        setValidated(false);
      } else {
        setValidated(true);
      }
    } else {
      setSelectedResolutionDateTime({
        ...selectedResolutionDateTime,
        timePicker: e.target.value
      });
    }
  };

  const handleOnChangeEventDateTimePicker = (e) => {
    if (e.target.id === 'datePicker') {
      setSelectedEventDateTimePicker({
        ...selectedEventDateTimePicker,
        datePicker: e.target.value
      });
      if (e.target.value > selectedResolutionDateTime.datePicker) {
        setValidated(false);
      } else {
        setValidated(true);
      }
    } else {
      setSelectedEventDateTimePicker({
        ...selectedEventDateTimePicker,
        timePicker: e.target.value
      });
    }
  };

  const handleTextInputCounter = (text) => {
    setTextInputCounter(text.length);
  };

  const handleTextAreaCounter = (text) => {
    setTextAreaCounter(text.length);
  };

  const handleFileChange = (filesArray, event) => {
    let files = filesArray;
    setSelectedFiles(files);
    const validatedSize = checkFileSize(files, event);
    const validatedExtensions = checkFileExtension(files, event);

    if (validatedSize && validatedExtensions) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  const handleEquipmentChange = (event) => {
    setSelectedEquipments(event);
  };

  const checkFileSize = (files, event) => {
    let errMessage = document.getElementById('formFile-errMessageSize');
    let size = 0;
    for (let file of files) {
      if (file.file !== null) {
        size = file.file.size + size;
      }
    }
    if (event === undefined) {
      errMessage?.classList.remove('visible');
      return true;
    } else if (event !== undefined) {
      if (size > 50 * (1024 * 1024) && event !== undefined) {
        event.target.classList.add('is-invalid');
        event.target.classList.remove('is-valid');
        errMessage.classList.add('visible');

        return false;
      } else {
        event.target.classList.remove('is-invalid');
        event.target.classList.add('is-valid');
        errMessage.classList.remove('visible');
        return true;
      }
    } else if (size < 50 * (1024 * 1024)) {
      for (let file of files) {
        if (file.event !== null) {
          file.event.target.classList.remove('is-invalid');
          file.event.target.classList.add('is-valid');
          errMessage?.classList.remove('visible');
        }
      }
      return true;
    }
  };

  const checkFileExtension = (files, event) => {
    let errMessage = document.getElementById('formFile-errMessageExtension');

    let fileType = files[0]?.file?.type;
    if (event === undefined) {
      errMessage?.classList.remove('visible');
      return true;
    } else if (FileExtensionsEnum.some((fileEnum) => fileEnum.type === fileType)) {
      event?.target.classList.remove('is-invalid');
      event?.target.classList.add('is-valid');
      errMessage.classList.remove('visible');
      return true;
    } else {
      event?.target.classList.add('is-invalid');
      event?.target.classList.remove('is-valid');
      errMessage.classList.add('visible');
      return false;
    }
  };

  return {
    textAreaCounter,
    textInputCounter,
    validated,
    modalShow,
    kpjm,
    email,
    selectedFiles,
    selectedEquipments,
    selectedOption,
    selectedTextInput,
    selectedTextAreaInput,
    selectedResolutionDateTime,
    selectedEventDateTimePicker,
    selectedSource,
    checkBox,
    setModalShow,
    setSelectedFiles,
    setSelectedEquipments,
    handleOnChangeKpjm,
    handleOnChangeEmail,
    handleOnChangeRadio,
    handleOnChangeTextInput,
    handleOnChangeTextAreaInput,
    handleOnChangeResolutionDateTimePicker,
    handleOnChangeEventDateTimePicker,
    handleFileChange,
    handleEquipmentChange,
    handleOnChangeSource,
    handleOnChangeCheckBox
  };
}

export default useSharedState;
