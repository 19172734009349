import { BrowserRouter as BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './components/NotFound/NotFound';
import Confirmation from './components/Confirmation/Confirmation';
import BaseForm from './components/Snap/BaseForm';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BaseForm />} />
        <Route path="/:location?" element={<BaseForm />} />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="*" index element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
