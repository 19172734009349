import React from 'react';
import './GdprBox.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const GdprBox = (props) => {
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered animation={false}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Ochrana osobních údajů (GDPR)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Osobní údaje subjektu údajů jsou zpracovávány společností ČEZ, a. s., IČO 45274649, se sídlem Praha 4, Duhová
          2/1444, PSČ 14053, v souladu s příslušnými aktuálně platnými a účinnými právními předpisy České republiky a
          Evropské unie. Bližší informace týkající se zpracování osobních údajů, práv subjektu údajů, kontaktu na
          pověřence pro ochranu osobních údajů a právních předpisů, na jejichž základě je zpracování prováděno, jsou
          dostupné na stránkách&nbsp;
          <a href="https://www.cez.cz/cs/o-cez/cez/ochrana-osobnich-udaju/informace-o-zpracovani-osobnich-udaju">
            https://www.cez.cz/cs/o-cez/cez/ochrana-osobnich-udaju/informace-o-zpracovani-osobnich-udaju
          </a>
          .
        </p>
        <Button variant="gdpr-close" className="mx-auto" onClick={props.onHide}>
          Zavřít
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default GdprBox;
