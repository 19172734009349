import axios from 'axios';
import { API_BASE_URL, APP_ENVIRONMENT } from '../settings/config';
import BearerToken from '../utils/BearerToken';

const api = axios.create({
  baseURL: `${API_BASE_URL}/${APP_ENVIRONMENT}`
});

api.interceptors.request.use(
  async (config) => {
    let token = await BearerToken.GenerateBearerToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.log('axios.request');
    console.log(error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    switch (error.response.status) {
      case 400:
        console.log(error);
        return Promise.reject(error);
      case 401:
        console.log(`${error.response.status} - Při načtení se něco nepovedlo, oprávnění není platné.`);
        return Promise.reject(error);

      case 403:
        console.log(error);
        return Promise.reject(error);

      case 404:
        console.log(error);
        return error.response;

      case 409:
        console.log(error);
        return Promise.reject(error);

      default:
        console.log(error);
        return Promise.reject(error);
    }
  }
);

export default {
  get: api.get,
  post: api.post,
  put: api.put,
  delete: api.delete,
  patch: api.patch
};
