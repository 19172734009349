import { useMutation } from '@tanstack/react-query';
import { create } from '../useApiService';
import { ENP_FORM_V1_SAVE_SNAP, ENP_FORM_V1_SAVE_SNAP_ANONYMOUS } from '../../settings/config';
import { useNavigate } from 'react-router-dom';

const useFormCrud = (handleLoading, handleFailure, scrollToTop) => {
  const navigate = useNavigate();
  const { mutateAsync: createSnapAnonymous } = useMutation({
    mutationFn: (newSnap) => create(ENP_FORM_V1_SAVE_SNAP_ANONYMOUS, newSnap),
    onMutate: () => {
      handleLoading(true);
    },
    onSuccess: () => {
      handleLoading(false);
      navigate('/confirmation');
    },
    onError: () => {
      handleLoading(false);
      handleFailure(true);
      scrollToTop();
    }
  });

  const { mutateAsync: createSnapIdentified } = useMutation({
    mutationFn: (newSnap) => create(ENP_FORM_V1_SAVE_SNAP, newSnap),
    onMutate: () => {
      handleLoading(true);
    },
    onSuccess: () => {
      handleLoading(false);
      navigate('/confirmation');
    },
    onError: () => {
      handleLoading(false);
      handleFailure(true);
      scrollToTop();
    }
  });
  return { createSnapAnonymous, createSnapIdentified };
};

export { useFormCrud };
