import { Link } from 'react-router-dom';
import './NewRequestButton.scss';

const NewRequestButton = (props) => {
  const redirect = props.props?.failure && `failure=${props.props?.failure}`;
  return (
    <Link
      to={{
        pathname: '/',
        search: redirect
      }}
      state={props}
      className="new-request-button"
    >
      Vytvořit další požadavek
    </Link>
  );
};

export default NewRequestButton;
