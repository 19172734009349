import React from 'react';
import './SubmitButton.scss';

const SubmitButton = (props) => {
  return (
    <button type="button" data-testid={props.testId} onClick={props.onSubmit} className="submit-button">
      Odeslat
    </button>
  );
};

export default SubmitButton;
