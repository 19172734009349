import React, { useState } from 'react';
import InfoImg from '../../../img/Information.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddButton from '../Buttons/AddButton/AddButton';

export const FormCheckBox = (props) => {
  const { label, onClick, id } = props;
  return (
    <div className="form-check">
      <input className="form-checkBox-input" type="checkbox" id={id} onClick={onClick} />
      <label className="form-checkBox-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export const FormInput = (props) => {
  const {
    type,
    id,
    className,
    label,
    placeholder,
    required,
    disabled = false,
    onChange,
    validationMessage,
    pattern,
    counter,
    maxLength,
    value
  } = props;
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        <div className="field-label">{label}</div>
        {counter >= 0 && (
          <div className="text-counter">
            {counter}/{maxLength}
          </div>
        )}
      </label>
      <input
        type={type}
        id={id}
        name={id}
        className={className}
        onChange={onChange}
        required={required}
        pattern={pattern}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        data-testid={id}
        value={value}
      ></input>
      <div className="invalid-feedback">{validationMessage}</div>
    </div>
  );
};

export const FormTextArea = (props) => {
  const { className, label, tooltip, required, onChange, validationMessage, rows, id, counter, maxLength, value } =
    props;
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        <div className="field-label">
          {label}
          <OverlayTrigger overlay={<Tooltip id="rozcestnikTooltip">{tooltip}</Tooltip>}>
            <img className="tooltip-icon" src={InfoImg} alt="" />
          </OverlayTrigger>
        </div>
        <div className="text-counter">
          {counter}/{maxLength}
        </div>
      </label>
      <textarea
        className={className}
        id={id}
        rows={rows}
        onChange={onChange}
        required={required}
        maxLength={maxLength}
        data-testid={id}
        value={value}
      ></textarea>
      <div className="invalid-feedback">{validationMessage}</div>
    </div>
  );
};

export const FormDateTimeInput = (props) => {
  const { className, label, required, onChange, id, testId, resolutionDate, value } = props;
  const today = new Date().toISOString().slice(0, 10); // získání dnešního data
  const maxDate = resolutionDate && resolutionDate < today ? resolutionDate : today; // pokud je resolutionDate menší než dnešní datum, použijeme resolutionDate
  return (
    <div className="mb-3">
      <div className="row">
        <label htmlFor={id} className="form-label">
          <div className="field-label">{label}</div>
        </label>
        <div className="col">
          <input
            type="date"
            className={className}
            id="datePicker"
            data-testid={testId[0]}
            onChange={onChange}
            required={required}
            defaultValue={required ? today : null} // nastavení výchozího data
            max={required ? today : maxDate} // nastavení maximálního data
            placeholder={today}
            value={value}
          />
          <div className="invalid-feedback">
            {id === `resolutionDateTime` ? `Datum nesmí být v budoucnosti!` : `Datum nesmí být po datu zjištění!`}
          </div>
        </div>
        <div className="col">
          <input
            type="time"
            className={className}
            id="timePicker"
            data-testid={testId[1]}
            onChange={onChange}
            required={false}
          />
        </div>
      </div>
    </div>
  );
};

export const FormFileInput = (props) => {
  const { className, label, fileChange, validationMessageSize, validationMessageExtension, id, accept } = props;

  // Použijeme stav pro uchování dat vstupních řádků a počítadlo pro generování čísel
  const [inputRows, setInputRows] = useState([]);
  const [rowCounter, setRowCounter] = useState(1);

  // Funkce pro přidání nového vstupního řádku
  const addInputRow = () => {
    setInputRows([...inputRows, { id: rowCounter, file: null, event: null }]);
    setRowCounter(rowCounter + 1);
  };

  // Funkce pro odebrání konkrétního řádku
  const removeInputRow = (id) => {
    const updatedRows = inputRows.filter((row) => row.id !== id);
    fileChange(updatedRows);
    setInputRows(updatedRows);
  };

  // Funkce pro uložení souboru do pole
  const handleFileChange = (event, id) => {
    const file = event.target.files[0];

    const updatedRows = inputRows.map((row) => {
      if (row.id === id) {
        return { ...row, file, event };
      }
      return row;
    });

    fileChange(updatedRows, event); // Změna zde z inputRows na updatedRows
    setInputRows(updatedRows);
  };

  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        <div className="field-label">{label}</div>
      </label>
      <AddButton addInputRow={addInputRow} id={id} />
      {inputRows.map((row) => (
        <div key={row.id} className="spacing">
          <div className="input-group">
            <input
              type="file"
              id={`formFile-${row.id}`}
              data-testid={`${id}-${row.id}`}
              className={className}
              onChange={(event) => handleFileChange(event, row.id)}
              accept={accept}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="inputGroupFileAddon04"
              onClick={() => removeInputRow(row.id)}
              data-testid={`${id}-removeButton`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <rect width="20" height="20" fill="none" />
                <path
                  d="M15 5.875L14.125 5L10 9.125L5.875 5L5 5.875L9.125 10L5 14.125L5.875 15L10 10.875L14.125 15L15 14.125L10.875 10L15 5.875Z"
                  fill="#03031A"
                />
              </svg>
            </button>
          </div>
        </div>
      ))}
      <div id={`${id}-errMessageSize`} className="invalid-feedback">
        {validationMessageSize}
      </div>
      <div id={`${id}-errMessageExtension`} className="invalid-feedback">
        {validationMessageExtension}
      </div>
    </div>
  );
};

export const FormGroupInput = (props) => {
  const { className, label, onChange, validationMessage, id, testId, data } = props;

  return (
    <div className="mb-3">
      <label className="form-label" htmlFor={id}>
        <div className="field-label">{label}</div>
      </label>
      <select className={className} id={id} data-testid={testId} onChange={onChange} defaultValue="">
        <option value="" disabled hidden>
          Vyberte...
        </option>
        {data.map((item) => (
          <option key={item.value} value={item.value}>
            {item.title} - {item.description}
          </option>
        ))}
      </select>
      <div className="invalid-feedback">{validationMessage}</div>
    </div>
  );
};

export const FormEquipmentInput = (props) => {
  const { className, label, equipmentChange, testId, id } = props;

  // Použijeme stav pro uchování dat vstupních řádků a počítadlo pro generování čísel
  const [inputRows, setInputRows] = useState([]);
  const [rowCounter, setRowCounter] = useState(0);

  // Funkce pro přidání nového vstupního řádku
  const addInputRow = () => {
    setInputRows([...inputRows, { id: rowCounter, value: '' }]);
    setRowCounter(rowCounter + 1);
  };

  // Funkce pro odebrání konkrétního řádku
  const removeInputRow = (id) => {
    const updatedRows = inputRows.filter((row) => row.id !== id);
    equipmentChange(updatedRows);
    setInputRows(updatedRows);
  };

  const handleInputChange = (id, value) => {
    const updatedRows = inputRows.map((row) => {
      if (row.id === id) {
        return { ...row, value };
      }
      return row;
    });
    equipmentChange(updatedRows);
    setInputRows(updatedRows);
  };
  return (
    <div className="mb-3">
      <label className="input-group form-label">
        <div className="field-label">{label}</div>
      </label>
      <AddButton addInputRow={addInputRow} id={id} />
      {inputRows.map((row) => (
        <div key={row.id} className="spacing">
          <div className="input-group">
            <input
              type="text"
              className={className}
              data-testid={`${testId}-${row.id}`}
              onChange={(e) => handleInputChange(row.id, e.target.value)}
              id={`input-${row.id}`}
              value={row.value}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => removeInputRow(row.id)}
              id="button-addon2"
              data-testid={`${testId}-removeButton`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <rect width="20" height="20" fill="none" />
                <path
                  d="M15 5.875L14.125 5L10 9.125L5.875 5L5 5.875L9.125 10L5 14.125L5.875 15L10 10.875L14.125 15L15 14.125L10.875 10L15 5.875Z"
                  fill="#03031A"
                />
              </svg>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
