// Environment
export const APP_ENVIRONMENT = window._env_ ? window._env_.REACT_APP_ENVIRONMENT : 'development';
// API
export const API_BASE_URL = window._env_ ? window._env_.REACT_APP_API_BASE_URL : 'http://localhost:8080';
export const CONSUMER_KEY = window._env_ ? window._env_.REACT_APP_UNIQUE_PRIVATE_VARIABLE : 'consumer-key';
export const CONSUMER_SECRET = window._env_ ? window._env_.REACT_APP_UNIQUE_VARIABLE : 'consumer-secret';
export const RECAPTCHA_SITE_KEY = window._env_ ? window._env_.REACT_APP_RECAPTCHA_SITE_KEY : 'recaptcha-site-key';

export const ENP_AUTH_V1_LOGIN_ANONYMOUS = 'token';
export const ENP_FORM_V1_SAVE_SNAP_ANONYMOUS = 'form/v1/save-snap-anonymous';
export const ENP_FORM_V1_SAVE_SNAP = 'form/v1/save-snap';
