import React from 'react';
export const TOOLTIP_TEXTAREA = () => (
  <ul className="snap-tooltip">
    Do popisu požadavku uveďte:
    <li>Kde, kdy, co se stalo/ bylo zjištěno</li>
    <li>Identifikované nedostatky a známé/ možné příčiny</li>
    <li>Co bylo/ mohlo být ohroženo</li>
    <li>Možnost zranění, škody, vznik nebezp. provozních podmínek?</li>
    <li>Realizovaná/ navržená opatření pro zmírnění/ odstranění následků, nebo zabránění opakování</li>
    <li>V případě podnětu na zlepšení přínos realizace</li>
  </ul>
);

export const TOOLTIP_FORM = () => `Přes formulář můžete nahlásit např. závadu, porušení pracovních předpisů, ...`;
export const TOOLTIP_ADDITIONAL_INFO = () => (
  <div style={{ textAlign: 'left' }}>
    <p>Kontaktní údaje slouží pouze k případnému doplnění informací.</p>
    <p>Není ověřena identita zadavatele, proto bude požadavek zapsán jako anonymní.</p>
  </div>
);
export const TOOLTIP_IDENTIFIED = () =>
  `Formulář je pro ověřeného uživatele a můžete přes něj nahlásit např. závadu, porušení pracovních předpisů, ...`;
export const TOOLTIP_ANONYMOUS = () =>
  `Formulář je anonymní a můžete přes něj nahlásit např. závadu, porušení pracovních předpisů, ...`;
