export const SourceEnum = [
  { value: 'AUDEX', title: 'AUDEX', description: 'EXTERNÍ - OVĚŘOVACÍ, CERTIFIKAČNÍ AUDITY RESP. PROVĚRKY' },
  { value: 'AUDIN', title: 'AUDIN', description: 'INTERNÍ - AUDIT' },
  { value: 'BENMO', title: 'BENMO', description: 'OBECNÉ - BENCHMARKING' },
  { value: 'DNBIN', title: 'DNBIN', description: 'INTERNÍ - DOHLED NAD SYSTÉMEM ŘÍZENÍ BEZPEČNOSTI' },
  { value: 'DRBIN', title: 'DRBIN', description: 'INTERNÍ - NEZÁVISLÝ DOHLED NAD SYSTÉMEM ŘÍZENÍ BEZPEČNOSTI' },
  { value: 'DSOEX', title: 'DSOEX', description: 'EXTERNÍ - DOHLED STÁTNÍCH ORGÁNŮ' },
  { value: 'HDOIN', title: 'HDOIN', description: 'INTERNÍ - HODNOCENÍ A KVALIFIKACE DODAVATELŮ' },
  { value: 'HSRIN', title: 'HSRIN', description: 'INTERNÍ - HODNOCENÍ SYSTÉMU ŘÍZENÍ' },
  { value: 'KONHO', title: 'KONHO', description: 'OBECNÉ - KONTINUÁLNÍ HODNOCENÍ' },
  { value: 'KOPIN', title: 'KOPIN', description: 'INTERNÍ - KOMPLEXNÍ PROVĚRKY' },
  { value: 'MHOEX', title: 'MHOEX', description: 'EXTERNÍ - NADNÁRODNÍ A PARTNERSKÉ HODNOCENÍ' },
  { value: 'SEBEH', title: 'SEBEH', description: 'OBECNÉ - SEBEHODNOCENÍ' },
  { value: 'SNBOX', title: 'SNBOX', description: 'OBECNÉ - ZÁZNAM ZE SNAP BOXU' },
  { value: 'SPKIN', title: 'SPKIN', description: 'INTERNÍ - SPOLEČNÁ KONTROLA VEDOUCÍCH' },
  { value: 'UDEXT', title: 'UDEXT', description: 'EXTERNÍ - EXTERNÍ UDÁLOSTI' },
  { value: 'ZKEXT', title: 'ZKEXT', description: 'EXTERNÍ - EXTERNÍ ZKUŠENOSTI' }
];
