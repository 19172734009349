import React from 'react';
import './Confirmation.scss';
import CezLogo from '../../img/Logo-CEZ-group.svg';
import Checkmark from '../../img/Checkmark-outline.svg';
import NewRequestButton from '../Others/Buttons/NewRequestButton/NewRequestButton';

const Confirmation = () => {
  return (
    <>
      <div className="cez-image">
        <img src={CezLogo} alt="ČEZ" />
        <p>ČEZ</p>
      </div>
      <div className="wrapper-success">
        <div className="container-success">
          <div className="form-text">
            <h1>Požadavek na činnost</h1>
          </div>
          <img src={Checkmark} />
          <p className="description">Děkujeme, váš požadavek byl úspěšně odeslán. Podíváme se na něj co nejdříve.</p>
        </div>
        <NewRequestButton />
      </div>
    </>
  );
};

export default Confirmation;
